// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-01-rissottofinance-history-index-md": () => import("./../src/pages/blog/01-rissottofinance-history/index.md" /* webpackChunkName: "component---src-pages-blog-01-rissottofinance-history-index-md" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bug-bounty-mdx": () => import("./../src/pages/bug-bounty.mdx" /* webpackChunkName: "component---src-pages-bug-bounty-mdx" */),
  "component---src-pages-compare-js": () => import("./../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-confirm-js": () => import("./../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-disclaimer-mdx": () => import("./../src/pages/disclaimer.mdx" /* webpackChunkName: "component---src-pages-disclaimer-mdx" */),
  "component---src-pages-docs-index-tsx": () => import("./../src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-docs-v-1-01-frontend-integration-01-connect-to-rissottofinance-md": () => import("./../src/pages/docs/v1/01-frontend-integration/01-connect-to-rissottofinance.md" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-01-connect-to-rissottofinance-md" */),
  "component---src-pages-docs-v-1-01-frontend-integration-index-tsx": () => import("./../src/pages/docs/v1/01-frontend-integration/index.tsx" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-index-tsx" */),
  "component---src-pages-docs-v-1-index-tsx": () => import("./../src/pages/docs/v1/index.tsx" /* webpackChunkName: "component---src-pages-docs-v-1-index-tsx" */),
  "component---src-pages-docs-v-2-01-protocol-overview-01-how-rissottofinance-works-md": () => import("./../src/pages/docs/v2/01-protocol-overview/01-how-rissottofinance-works.md" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-01-how-rissottofinance-works-md" */),
  "component---src-pages-docs-v-2-01-protocol-overview-index-tsx": () => import("./../src/pages/docs/v2/01-protocol-overview/index.tsx" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-index-tsx" */),
  "component---src-pages-docs-v-2-01-protocol-overview-reference-advanced-topics-js": () => import("./../src/pages/docs/v2/01-protocol-overview/reference/Advanced-topics.js" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-reference-advanced-topics-js" */),
  "component---src-pages-docs-v-2-01-protocol-overview-reference-core-concepts-js": () => import("./../src/pages/docs/v2/01-protocol-overview/reference/Core-concepts.js" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-reference-core-concepts-js" */),
  "component---src-pages-docs-v-2-01-protocol-overview-reference-whitepaper-js": () => import("./../src/pages/docs/v2/01-protocol-overview/reference/Whitepaper.js" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-reference-whitepaper-js" */),
  "component---src-pages-docs-v-2-index-tsx": () => import("./../src/pages/docs/v2/index.tsx" /* webpackChunkName: "component---src-pages-docs-v-2-index-tsx" */),
  "component---src-pages-faq-index-md": () => import("./../src/pages/faq/index.md" /* webpackChunkName: "component---src-pages-faq-index-md" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-howsafe-js": () => import("./../src/pages/howsafe.js" /* webpackChunkName: "component---src-pages-howsafe-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnership-js": () => import("./../src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-pages-performance-js": () => import("./../src/pages/performance.js" /* webpackChunkName: "component---src-pages-performance-js" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-roadmap-js": () => import("./../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-of-service-mdx": () => import("./../src/pages/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-terms-of-service-mdx" */),
  "component---src-pages-tokenomics-js": () => import("./../src/pages/tokenomics.js" /* webpackChunkName: "component---src-pages-tokenomics-js" */),
  "component---src-pages-trademarks-mdx": () => import("./../src/pages/trademarks.mdx" /* webpackChunkName: "component---src-pages-trademarks-mdx" */)
}

